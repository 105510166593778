
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.overview {
  display: flex;
  flex-direction: column;
  .padding-wrapper {
    padding: 25px;
    @include bp-lg-laptop {
      padding: 50px;
    }
  }
  .top-row-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    @include bp-md-tablet {
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 21px;
    }

    .g-button,
    .skeleton-button {
      padding: 8px 25px;
      margin-top: 10px;
      @include bp-md-tablet {
        margin-top: 0;
        margin-right: 10px;
      }
    }
  }
  .search {
    width: 100%;
    max-width: 245px;
    padding: 0;
    margin-top: 20px;
    @include bp-md-tablet {
      margin-top: 0;
      margin-left: auto;
    }
    ::v-deep {
      .input-element {
        padding: 10px 0;
        padding-right: 5px;
      }
    }
  }
  .table {
    width: 100%;
    overflow-x: auto;

    &-overflow {
      width: 100%;
      margin-bottom: 30px;
      overflow-x: auto;
      @include bp-lg-laptop {
        min-height: 475px;
        margin-bottom: 50px;
      }
    }

    ::v-deep {
      .g-table__cell {
        &--actions {
          width: 141px;
          padding: 0 10px;
          background-color: rgba($gk-grey, 0.27);
          border-color: rgba($gk-grey, 0.27);
          @include bp-lg-laptop {
            width: 250px;
            padding: 5px 10px;
            background-color: inherit;
            border-color: $gk-grey-3;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      .g-divider {
        --g-divider-color: #{$gk-light-grey};
        margin: 5px 0;
      }
      .draft-switch {
        padding: 0 10px;
        @include bp-lg-laptop {
          padding: 0 12px;

          --g-switch-width: 40px;
        }

        --g-switch-width: 28px;
      }
    }

    &__pagination {
      margin: 0 auto;
      margin-top: auto;
    }
  }
}

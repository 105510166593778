
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.cms-route-header {
  display: flex;
  align-items: center;
  padding: 30px 22px;
  border-bottom: 1px solid $gk-light-grey;

  @include bp-lg-laptop {
    padding: 25px 50px;
  }

  .menu-button {
    padding: 5px;
    margin-right: 20px;
  }
  .header__name {
    @include bp-lg-laptop {
      margin: 0;
    }

    margin: 0 auto;
    color: $gk-dark-blue;
  }
  // This makes the logo centered with even with close button
  &::after {
    width: 36px;
    margin-left: 20px;
    content: '';
    @include bp-lg-laptop {
      display: none;
    }
  }
}

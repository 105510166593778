
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-button {
  padding: 8px 25px;
}
.btn-close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  width: 48px;
  svg {
    width: 100%;
  }
}
.products-list {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 32px;
  .draggable-item {
    display: flex;
    .handle {
      max-width: 16px;
      margin-right: 1em;
      svg {
        width: 100%;
      }
    }
  }
}


          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-slide-from-left-enter,
.modal-slide-from-left-leave-to {
  opacity: 0;

  .content {
    transform: translateX(-100%);
  }
}
.modal-slide-from-right-enter,
.modal-slide-from-right-leave-to {
  opacity: 0;

  .content {
    transform: translateX(100%);
  }
}
.modal-slide-from-left-enter-to,
.modal-slide-from-left-leave,
.modal-slide-from-right-enter-to,
.modal-slide-from-right-leave {
  opacity: 1;
  .content {
    transform: translateX(0);
  }
}
.modal-slide-from-left-enter-active,
.modal-slide-from-left-leave-active,
.modal-slide-from-right-enter-active,
.modal-slide-from-right-leave-active {
  transition: opacity 0.5s ease;

  .content {
    transition: transform 0.5s ease;
  }
}
.g-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  overflow-y: scroll;
  &.overlay {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .content {
    box-sizing: border-box;

    &.type--modal {
      margin: auto;
    }

    &.type--drawer {
      width: 100%;
      max-width: 420px;
      height: 100%;

      &.from-left {
        margin: 0 auto 0 0;
      }
      &.from-right {
        margin: 0 0 0 auto;
      }
    }
    .content-header {
      display: flex;
    }
    .content-body {
      position: relative;
    }
    .content-footer {
      display: flex;
      margin-top: auto;
    }
  }
}

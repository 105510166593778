
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.modal-delete {
  --g-modal-background-color: white;
  ::v-deep {
    padding: 27px;
    > .content {
      width: 100%;
      max-width: 500px;
    }
  }
  .delete-content {
    padding: 60px 70px;
    text-align: center;
    @include bp-lg-laptop {
      padding: 70px;
    }
    .message {
      margin: 0;
    }
  }
  .modal-heading {
    margin-top: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    @include bp-lg-laptop {
      align-items: center;
    }

    .g-button {
      justify-content: center;
    }

    .cancel {
      margin-top: 20px;
      @include bp-lg-laptop {
        margin-top: 0;
      }
    }
  }
}

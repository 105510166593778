
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.alert {
  position: relative;
  padding: 16px;
  margin-bottom: 16px;
  &.alert-danger {
    background-color: $gk-red;
    color: $gk-white;
  }
  &.alert-success {
    background-color: $gk-green;
    // color: $gk-white;
  }
}
